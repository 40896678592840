/* Login.css */

.container1 {
    margin: 0px !important;
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* Full viewport height */
    background-color: #f8f9fa; /* Optional: light background color for the entire page */
}

.login-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    width: 300px; /* Fixed width for the login container */
    padding: 10px; 
    background-color: #fff; /* Light background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.logo1 {
    width: 150px; /* Adjust the logo size as needed */
    margin-bottom: 10px; /* Space below the logo */
}

h2 {
    margin-bottom: 20px; /* Space between title and form */
    color: #333; /* Dark text color */
}

.form-group {
    margin-bottom: 15px; /* Space between input fields */
    width: 100%; /* Ensure full width */
}

label {
    display: block; /* Stack labels above inputs */
    margin-bottom: 5px; /* Space between label and input */
    color: #555; /* Text color for labels */
}

input {
    width: 100%; /* Full width inputs */
    padding: 10px; /* Padding for inputs */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); /* Inner shadow */
}

button {
    margin: 20px 0px 45px 0px;
    width: 100%; /* Full width button */
    padding: 10px; /* Padding for button */
    background-color: #188b3E; /* Button color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    border-radius: 25px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.3s; /* Transition for hover effect */
}

button:hover {
    background-color: #146b2e; /* Darker green on hover */
}

.forgot-password {
    color: #188b3E !important;
    margin-top: 10px; /* Space above the link */
}

.forgot-password a {
    color: #188b3E !important;
    text-decoration: none; /* No underline */
    font-weight: bold; /* Bold text */
}
