

/* Style for the date input container */
.date-input-label {
    display: flex; /* Use flexbox to align labels side by side */
    justify-content: space-between; /* Space between labels */
    margin-bottom: 10px; /* Space below the labels */
    width: 200px !important;
}

.from-label, .to-label {
    margin-right: 5px; /* Space to the right of the "From Date" label */
}

/* Optional: Add a specific width for the labels if needed */
.from-label, .to-label {
    flex: 1; /* Equal space for both labels */
    text-align: left; /* Align text to the left */
}

.date-input-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    /* margin-bottom: 20px 10px 20px 10px !important; */
    width: 500px !important;
    margin: 10px 10px 10px 250px !important;
    padding: 20px !important;
    background-color: aliceblue !important;
    border-color: 2px solid brown !important;
}

/* Wrapper for date inputs */
.date-input-wrapper {
    display: flex;
    justify-content: center; /* Center inputs horizontally */
    align-items: center; /* Center items vertically */
    margin-bottom: 10px; /* Space below the date inputs */
}

/* Style for each date input */
.date-input {
    padding: 5px; /* Padding inside the input */
    margin-right: 10px; /* Space between inputs */
    border: 1px solid #7f223d; /* Border color */
    border-radius: 4px; /* Rounded corners */
    width: 120px; /* Fixed width for consistency */
}

/* Style for the OK button */
.ok-button {
    padding: 5px 10px; /* Adjusted padding for a smaller button */
    background-color: #7f223d; /* Button color */
    color: white; /* Text color */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s; /* Smooth transition */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    width: auto; /* Let width adjust automatically */
    max-width: 100px; /* Set a maximum width for the button */
}

/* Hover effect for the OK button */
.ok-button:hover {
    background-color: #a32949; /* Darker shade on hover */
}
