/* Container for the entire table */
.table-container-todaloandue {
    width: 800px;
    max-width: 1200px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 100px;
    /* margin-left: 300px !important; */
    position: relative; /* Ensure the container is a positioned element */
   
}
.downloadcotainer {
   
    display: flex;
    justify-content: flex-end; /* Aligns the button to the right */
    padding: 10px; /* Optional padding around the container */
}

.download-button {
    padding: 2px !important; /* Smaller padding for a compact look */
    font-size: 0.50rem; /* Smaller font size */
    width: 30px !important; /* Set button width */
    height: 30px !important; /* Set button height */
   
}



/* City group styling */
.city-group {
    margin-bottom: 15px;
    margin-right: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

/* Header for each city */
.city-header {
    background-color: #188b3E;
    padding: 15px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    transition: background-color 0.3s ease-in-out;
}

.city-header:hover {
    background-color: #188b3E; /* Darker green on hover */
}

/* City title styling */
.city-header h4 {
    margin: 0;
    font-size: 18px;
}

/* Action buttons (Add/Delete) styling */
.action-buttons {
    display: flex;
    gap: 15px;
    align-items: center;
}

.action-buttons .MuiSvgIcon-root {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.action-buttons .MuiSvgIcon-root:hover {
    transform: scale(1.2); /* Slight scaling on hover */
}

.action-buttons .MuiSvgIcon-root.add {
    color: green;
}

.action-buttons .MuiSvgIcon-root.delete {
    color: red;
}

/* Expand/Collapse icon */
.expand-icon {
    font-size: 16px;
    transition: transform 0.3s ease;
}

.expand-icon.rotate {
    transform: rotate(180deg); /* Rotates the arrow on expand */
}

/* City details when expanded */
.city-details {
    padding: 15px;
    background-color: #fff;
    border-top: 1px solid #ccc;
}

.city-details p {
    margin: 0;
    color: #333;
}

/* Dialog styles */
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.modal-content {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px; /* Reduce max width */
    width: 100%;
    padding: 15px; /* Decrease padding */
}

/* Form styles */
form {
    display: flex;
    flex-direction: column;
}



label {
    /* margin-bottom: 4px;  */
    font-weight: bold;
}

/* Input styles */
input[type="text"],
input[type="number"],
input[type="date"],
.status
select {
    height: 40px !important;
    padding: 1px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 0px !important; 
    transition: border-color 0.3s !important;
}

input[type="date"]
{
background-color: white;
height: 40px !important;
}
/* Focus styles */
input[type="text"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
select:focus {
    border-color: #007bff; /* Change to your desired focus color */
    outline: none;
}

/* Button styles */
button {
    padding: 8px 12px; /* Reduce padding */
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save {
    background-color: #28a745 !important; /* Green color for Save button */
    color: black !important;
}

.cancel {
    background-color: #dc3545 !important; /* Red color for Cancel button */
    color: black !important;
}

/* Hover styles */
.save :hover {
    background-color: #218838; /* Darker green on hover */
    color: black !important;
}

.cancel:hover {
    background-color: #c82333; /* Darker red on hover */
    color: black !important;
}
/* Adjustments for responsiveness */
@media (max-width: 768px) {
    .table-container {
        width: 100%;
        padding: 10px;
    }

    .city-header h4 {
        font-size: 16px;
    }

    .action-buttons .MuiSvgIcon-root {
        font-size: 20px;
    }
}

/* Responsive styles */
@media (max-width: 600px) {
    .modal-content {
        padding: 10px; /* Decrease padding on mobile */
        width: 90%; /* Reduce width on smaller screens */
    }

    input[type="text"],
    input[type="number"],
    input[type="date"],
    select {
        font-size: 14px; /* Smaller font on mobile */
    }

    button {
        font-size: 14px; /* Smaller button text */
    }
}

@media (max-width: 480px) {
    .action-buttons {
        gap: 10px;
    }

    .city-header h4 {
        font-size: 14px;
    }
}
