/* General Styles */
body {
    font-family: Arial, sans-serif; /* Default font */
}

.error {
    color: red;
    font-size: 12px;
}

.employee-details {
    justify-content: left;
    flex-wrap: wrap;
    gap: 10px;
    padding: 1px 0;
    margin: 10px !important;
}

.employee-detail-item {
    display: flex;
    flex-direction: column !important;
    gap: 1px;
}

.employee-action-buttons {
    display: flex;
    gap: 10px;
}

/* Button Styles */
.small-button {
    width: 200px;
    background-color: #188b3E;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
}

.small-button1, .small-button2 {
    width: 100px;
    background-color: #188b3E;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
}

.small-button2 {
    background-color: crimson;
}

.small-button:hover, .small-button1:hover {
    background-color: #145a2c;
}

/* Expand Icon Styles */
.expand-icon {
    margin-left: auto;
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
}

.employeecontainer {
    display: flex !important;
}

.sidebar {
    width: 240px;
}

.main-content {
    flex: 1;
    padding: 20px;
    margin: 70px 0 0 0;
}

h2 {
    margin-top: 0;
}

/* Form Container */
.form-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9 !important;
}

/* Loan Group Styles */
.loan-group {
    background-color: mediumseagreen !important;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.loan-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.loan-header h4 {
    color: white; /* Loan ID in white color */
    margin: 0;
}

/* Form Group Styles */
.form-group {
    margin-bottom: 15px;
    width: 500px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Table Styles */
.table-container-customer,
.table-container-employee,
.table-container-customer-loandue,
.table-container-employee-loandue {
    width: 100%;
    margin-top: 20px;
    /* margin-left: 100px !important; */
    overflow-x: auto;
    max-width: 800px;
    background-color: white !important;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
}

.table-header {
    border: 1px solid #ddd;
    padding: 8px;
    background-color: bisque;
    color: #333;
}

.table-cell {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table-row {
    background-color: #fff;
}

.table-row:hover {
    background-color: #f1f1f1;
}

/* Input Styles */
input[type="text"],
input[type="password"],
select.category-type {
    width: 100%;
    padding: 8px;
    /* margin-bottom: 15px; */
    border: 1px solid #ccc;
    border-radius: 4px;
}

input[type="text"]:focus,
input[type="password"]:focus,
select.category-type:focus {
    border-color: #7f223d !important;
    outline: none !important;
}

.employee-card-employee, .employee-card-customer {
    padding: 5px;
    margin: 15px 0;
    border-radius: 5px;
    transition: 0.3s;
}

.employee-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    width: 100%;
    color: white !important;
}

.maincard {
    background-color: mediumseagreen !important;
    border-radius: 20px;
}

.employee-name {
    margin-left: 10px;
    font-weight: bold;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 1200px) {
    .sidebar {
        width: 200px; /* Decrease sidebar width on medium devices */
    }

    .main-content {
        margin-left: 20px; /* Add margin to main content */
    }

    .form-container {
        max-width: 90%; /* Adjust form width */
    }
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%; /* Full width for sidebar on small devices */
        position: relative; /* Make it stack */
        margin-bottom: 20px; /* Space below sidebar */
    }

    .main-content {
        margin: 70px 0;
        padding: 10px; /* Add padding */
    }

    .loan-group {
        padding: 5px; /* Reduce padding */
    }

    .form-group {
        width: 100%; /* Full width for form groups */
    }

    .table-container-customer,
    .table-container-employee,
    .table-container-customer-loandue,
    .table-container-employee-loandue {
        margin-left: 0 !important; /* Remove left margin */
        margin-top: 55px;
    }

    .employee-name {
        font-size: 14px; /* Adjust font size */
    }
}

@media (max-width: 480px) {
    .small-button,
    .small-button1,
    .small-button2 {
        width: 100%; /* Full width for buttons */
    }

    .loan-header {
        flex-direction: column; /* Stack loan header elements */
        align-items: flex-start; /* Align items to the start */
    }

    .expand-icon {
        width: 15px !important; /* Adjust icon size */
        height: 15px !important;
    }

    .employee-details {
        padding: 0; /* Adjust padding for details */
    }
}

/* End of CSS */
