.table-container-particularloandue {
    margin: 20px 0; /* Add some margin for spacing */
    padding: 5px; /* Add padding for a better look */
    /* background-color: mediumseagreen !important;  */
    border-radius: 8px; /* Rounded corners for the container */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.loan-due-table {
    width: 100%; /* Make the table full width */
    border-collapse: collapse; /* Collapse borders for a cleaner look */
}

.loan-due-table th,
.loan-due-table td {
    padding: 5px; 
    text-align: left; /* Align text to the left */
    border-bottom: 1px solid #ddd; /* Light border for separation */
    background-color: mediumseagreen; /* Background color for table cells */
    color: black; /* Text color for table cells */
}

.loan-due-table th {
    font-weight: bold; /* Bold text for header */
}

.loan-due-table tr:hover {
    background-color: white !important; /* Background color on row hover */
    color: mediumseagreen !important; /* Change text color to mediumseagreen on hover */
}

.pending-loan {
    background-color: #ffe6e6 !important; /* Light red background for pending loans */
    font-weight: bold !important; /* Bold text for emphasis */
    color: red !important; /* Change text color to red */
}

/* Ensure the hover effect works even for pending loans */
.loan-due-table tr.pending-loan:hover {
    background-color: white !important; /* Override background color on hover */
    color: mediumseagreen !important; /* Change text color to mediumseagreen on hover */
}